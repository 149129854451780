import { FC } from "react";
import styled from "styled-components";

import LoaderBlue from "./LoaderBlue";

interface LoaderProps {
  width: string;
  height: string;
  color?: "blue";
  centered?: true;
  noPadding?: true;
  thickness?: number;
}

const CenteredLoader = styled(LoaderBlue)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Loader: FC<LoaderProps> = ({ width, color, height, centered, noPadding, thickness }) => {
  if (centered) {
    return (
      <CenteredLoader
        width={width}
        height={height}
        strokeWidth={thickness || 4}
        style={{ padding: noPadding ? undefined : "20px" }}
      />
    );
  }

  return (
    <LoaderBlue
      width={width}
      height={height}
      strokeWidth={thickness || 4}
      style={{ padding: noPadding ? undefined : "20px" }}
    />
  );
};

export default Loader;
